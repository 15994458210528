import { Typography, List, ListItem } from "@zigops-material/react";
import Company from "./Company";
import BillingsAndSubscription from "./BillingsAndSubscription";
import Payroll from "./Payroll";
import PayrollFunding from "./FundingPayroll";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/redux/configureStore";
import { setActiveSettingTab } from "@/redux/features/settings/settings";

function RenderView(viewId: string) {
  switch (viewId) {
    case "billings-and-subscription":
      return <BillingsAndSubscription />;
    case "payroll":
      return <Payroll />;
    case "funding-source":
      return <PayrollFunding />;
    default:
      return <Company />;
  }
}

function TribePlusSettings() {

  const activeTab = useSelector((state: RootState) => state.app_reducer.settings.activeTab);
  const dispatch = useDispatch();

  return (
    <div>
      <div className="lg-max:pt-5 flex h-20 w-full justify-between bg-orange-50/50 p-5 pl-7 pr-7 pt-3 dark:bg-transparent dark:text-gray-100">
        <div className="mt-2">
          <Typography className="text-2xl font-bold text-gray-800 dark:text-gray-300">
            Company Settings
          </Typography>
        </div>
        <div>
        </div>
      </div>
      <div className="flex h-[85vh] pl-4">
        <div className="col-span-2 mt-5 h-[95%] w-[300px] animate-fade-up overflow-y-auto rounded-md border border-gray-300 bg-white p-5 dark:border-gray-750 dark:bg-gray-850 ">
          <div>
            <Typography className="text-lg ">Company Name</Typography>
          </div>
          <div>
            <List>
              <ListItem selected={activeTab === "company"} onClick={() => dispatch(setActiveSettingTab("company"))}>
                Company
              </ListItem>
              <ListItem selected={activeTab === "billings-and-subscription"} onClick={() => dispatch(setActiveSettingTab("billings-and-subscription"))}>
                Billings and subscription
              </ListItem>
              <ListItem selected={activeTab === "payroll"} onClick={() => dispatch(setActiveSettingTab("payroll"))}>
                Payroll
              </ListItem>
              <ListItem selected={activeTab === "funding-source"} onClick={() => dispatch(setActiveSettingTab("funding-source"))}>
                Funding Source
              </ListItem>
            </List>
          </div>
        </div>
        <div className="w-[70%]">
          {RenderView(activeTab)}
        </div>
      </div>
    </div>
  );
}

export default TribePlusSettings;
