import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

export interface SettingsState {
    isModalOpen: boolean;
    activeTab: string;
}

// Initialize the settings state
const initialState: SettingsState = {
    isModalOpen: false,
    activeTab: "company",
};

export const settingsSlice = createSlice({
    name: "settings",
    initialState,
    reducers: {
        setIsSettingsModalOpen: (
            state: SettingsState,
            action: PayloadAction<boolean>,
        ) => {
            state.isModalOpen = action.payload;
        },
        setActiveSettingTab: (
            state: SettingsState,
            action: PayloadAction<string>,
        ) => {
            state.activeTab = action.payload;
        },
    },
});

// Action creators are generated for each case reducer function
export const { setIsSettingsModalOpen, setActiveSettingTab } = settingsSlice.actions;

export default settingsSlice.reducer;
