import { BankPaymentDetailsUpdateRequest } from "@/types/Commons";
import { PayrollPaymentDate } from "@/types/Payroll";
import { EXCEL_UPLOAD_INVITE_FORM, INFORMATION } from "@/types/User";

export interface UploadedDataState {
  result: Array<EXCEL_UPLOAD_INVITE_FORM>;
  count: number;
}

export interface AllocatedEmployeeAllowancesAndDeductions {
  id: string;
  employeeId: string;
  allowanceDeductionId: string;
  name: string;
  isRecurring: boolean;
  durationType: string;
  startDate: PayrollPaymentDate;
  endDate: PayrollPaymentDate;
  type: string;
  amount: number;
  createdBy: string;
  createdAt: string;
}

export interface AllocatedEmployeeAllowancesAndDeductionsInitial {
  id: string;
  employeeId: string;
  allowanceDeductionId: string;
  name: string;
  isRecurring: boolean;
  durationType: string;
  startDate: string;
  endDate: string;
  type: string;
  amount: number;
  createdBy: string;
  createdAt: string;
}

export interface AllocatedEmployeeAllowancesAndDeductionsInitial {
  id: string;
  employeeId: string;
  allowanceDeductionId: string;
  name: string;
  isRecurring: boolean;
  durationType: string;
  startDate: string;
  endDate: string;
  type: string;
  amount: number;
  createdBy: string;
  createdAt: string;
}

export interface EmployeeAllowanceFormat {
  allowanceDetails: AllocatedEmployeeAllowancesAndDeductions;
  userDetails: INFORMATION;
}

export interface EmployeeBankPaymentDetailsUpdateRequest extends BankPaymentDetailsUpdateRequest {
  employeeId: string;
}

export interface HomeAddress_Interface {
  employeeId: string;
  street: string;
  city: string;
  state: string;
  country: string;
  region: string;
  address: string;
}

export interface EmergencyContactDetails_Interface {
  employeeId: string;
  name: string;
  relationship: string;
  email: string;
  phoneNumber: string;
}

export interface Additions_Interface {
  bonus: number;
  loan: number;
  expenseReimbursements: number;
  other: number;
  otherDescription: string;
  overtime: number;
}

export interface Deductions_Interface {
  paye: number;
  healthContribution: number;
  loanRepayment: number;
  housingContribution: number;
}

export interface CompensationDetails_Interface {
  employeeId: string;
  grossSalary: number;
  jobTitle: string;
  group: string;
  employmentType: string;
  wageType: string;
  hasHousingContribution: boolean;
  hasHealthContribution: boolean;
  hasCompletePaymentDetails: boolean;
  additions: Additions_Interface;
  deductions: Deductions_Interface;
}

export interface BankDetails_Interface {
  bankName: string;
  swiftCode: string;
  sortCode: string;
  iban: string;
  accountNumber: string;
  abartn: string;
}

export interface MobileMoneyPaymentDetails_Interface {
  providerName: string;
  phoneNumber: string;
}

export interface PensionDetails_Interface {
  pensionDetailsId: number;
  employeeId: string;
  pfaName: string;
  pensionNumber: string;
  pensionRate: number;
}

export interface Employee {
  userId: string;
  tenantId: string;
  tenantName: string;
  status: string;
  employeeId: string;
  email: string;
  firstName: string;
  lastName: string;
  role: string;
  maritalStatus: string;
  statusAsOf: string;
  dateOfBirth: string;
  dateOfHire: string;
  dateUpdated: string;
  gender: string;
  personalId: string;
  taxId: string;
  paymentMethod: string;
  avatar: string;
  currency: string;
  healthInsuranceId: string;
  phoneNumber: string;
  homeAddress: HomeAddress_Interface;
  pensionDetails: PensionDetails_Interface;
  emergencyContactDetails: EmergencyContactDetails_Interface;
  compensationDetails: CompensationDetails_Interface;
  bankDetails: BankDetails_Interface;
  mobileMoneyPaymentDetails: MobileMoneyPaymentDetails_Interface;
}

export interface Employees {
  Employees: Employee[];
  ActiveEmployees: Employee[];
  InactiveEmployees: Employee[];
}

export interface UpdateEmployeeStatus {
  employeeId: string;
  newStatus: string;
}

export enum EmployeeActivityStatus {
  Active = "Active",
  Inactive = "Inactive"
}